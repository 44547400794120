@font-face {
  font-family: "monserrat-black";
  src: url("./Montserrat-Black.ttf") format("opentype");
}

@font-face {
  font-family: "monserrat-blackitalic";
  src: url("./Montserrat-BlackItalic.ttf") format("opentype");
}

@font-face {
  font-family: "monserrat-bold";
  src: url("./Montserrat-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "monserrat-bolditalic";
  src: url("./Montserrat-BoldItalic.ttf") format("opentype");
}

@font-face {
  font-family: "monserrat-extrabold";
  src: url("./Montserrat-ExtraBold.ttf") format("opentype");
}

@font-face {
  font-family: "monserrat-extrabolditalic";
  src: url("./Montserrat-ExtraBoldItalic.ttf") format("opentype");
}

@font-face {
  font-family: "monserrat-extralight";
  src: url("./Montserrat-ExtraLight.ttf") format("opentype");
}

@font-face {
  font-family: "monserrat-extralightitalic";
  src: url("./Montserrat-ExtraLightItalic.ttf") format("opentype");
}

@font-face {
  font-family: "monserrat-italic";
  src: url("./Montserrat-Italic.ttf") format("opentype");
}

@font-face {
  font-family: "monserrat-light";
  src: url("./Montserrat-Light.ttf") format("opentype");
}

@font-face {
  font-family: "monserrat-lightitalic";
  src: url("./Montserrat-LightItalic.ttf") format("opentype");
}

@font-face {
  font-family: "monserrat-medium";
  src: url("./Montserrat-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "monserrat-mediumitalic";
  src: url("./Montserrat-MediumItalic.ttf") format("opentype");
}

@font-face {
  font-family: "monserrat-regular";
  src: url("./Montserrat-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "monserrat-semibold";
  src: url("./Montserrat-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: "monserrat-semibolditalic";
  src: url("./Montserrat-SemiBoldItalic.ttf") format("opentype");
}

@font-face {
  font-family: "monserrat-thin";
  src: url("./Montserrat-Thin.ttf") format("opentype");
}

@font-face {
  font-family: "monserrat-thinitalic";
  src: url("./Montserrat-ThinItalic.ttf") format("opentype");
}
