.ng-select {
  background: var(--input-color) !important;
  padding: 0;
  margin-bottom: 22px;
  height: 56px;
  border-radius: 4px 4px 0 0;

  .ng-select-container {
    height: 100%;
    padding: 0 1em;
    color: var(--fg-color) !important;
  }

  .ng-placeholder,
  .ng-arrow,
  .ng-clear {
    color: var(--fg-color) !important;
  }

  .ng-select-container:after {
    border-bottom: 1px solid var(--fg-color) !important;
  }

  input {
    color: var(--mdc-filled-text-field-input-text-color);
    font-size: 1em;
  }

  &.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: var(--accent-color);
  }
}

.ng-dropdown-panel {
  background: var(--bg-color-solid);
  padding: 0.5em;

  .ng-option {
    padding: 0.5em 0;
  }

  .ng-star-inserted {
    color: var(--mdc-filled-text-field-input-text-color);
  }
}
