@use "sass:map";
@use "@angular/material" as mat;
@import "./assets/fonts/fonts.css";

@include mat.all-component-typographies();
@include mat.core();

$light-primary-palette-colors: (
  50: #e4f3eb,
  100: #bce2ce,
  200: #8fcfae,
  300: #62bc8d,
  400: #40ad74,
  500: #1e9f5c,
  600: #1a9754,
  700: #168d4a,
  800: #128341,
  900: #0a7230,
  A100: #a3ffc0,
  A200: #70ff9d,
  A400: #3dff7a,
  A700: #24ff68,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$light-accent-palette-colors: (
  50: #e4f4ec,
  100: #bce3cf,
  200: #8fd0af,
  300: #62bd8f,
  400: #41ae77,
  500: #1fa05f,
  600: #1b9857,
  700: #178e4d,
  800: #128443,
  900: #0a7332,
  A100: #a4ffc2,
  A200: #71ffa0,
  A400: #3eff7e,
  A700: #25ff6d,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$light-warn-palette-colors: (
  50: #fee0e2,
  100: #fdb3b6,
  200: #fb8085,
  300: #f94d54,
  400: #f82630,
  500: #f7000b,
  600: #f6000a,
  700: #f50008,
  800: #f30006,
  900: #f10003,
  A100: #ffffff,
  A200: #ffe5e5,
  A400: #ffb2b2,
  A700: #ff9898,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$light-my-primary: mat.define-palette($light-primary-palette-colors);
$light-my-accent: mat.define-palette($light-accent-palette-colors);
$light-my-warn: mat.define-palette($light-warn-palette-colors);

$light-primary-color: mat.get-color-from-palette($light-my-primary, 500);
$light-primary-color-900: mat.get-color-from-palette($light-my-primary, 900);
$light-primary-color-contrast: mat.get-color-from-palette($light-my-primary, "default-contrast");
$light-accent-color: mat.get-color-from-palette($light-my-accent, 500);
$light-accent-color-contrast: mat.get-color-from-palette($light-my-accent, "default-contrast");

$light-warn-color: mat.get-color-from-palette($light-my-warn, 500);
$light-warn-color-contrast: mat.get-color-from-palette($light-my-warn, "default-contrast");

$light-bg-color-solid: white;
$light-bg-color: rgba($light-bg-color-solid, 0.95);
$light-bg-color-85: rgba($light-bg-color-solid, 0.85);
$light-fg-color: rgb(17, 43, 16);

$negro-transparente: #0000002d;
$blanco-transparente: #ffffff80;
$light-input-color: #0000000a;

:root {
  --primary-color: #{$light-primary-color};
  --primary-color-900: #{$light-primary-color-900};
  --accent-color: #{$light-accent-color};
  --warn-color: #{$light-warn-color};
  --primary-color-contrast: #{$light-primary-color-contrast};
  --accent-color-contrast: #{$light-accent-color-contrast};
  --warn-color-contrast: #{$light-warn-color-contrast};
  --bg-color-solid: #{$light-bg-color-solid};
  --bg-color: #{$light-bg-color};
  --bg-color-85: #{$light-bg-color-85};
  --fg-color: #{$light-fg-color};
  --negro-transparente: #{$negro-transparente};
  --blanco-transparente: #{$blanco-transparente};
  --input-color: #{$light-input-color};
  // Colores
  // --azul: #{$azul};
  // --celeste: #{$celeste};
  // --rojo: #{$rojo};
  // --verde: #{$verde};
  // --amarillo: #{$amarillo};
  // --gris: #{$gris};
  // --naranja: #{$naranja};
  // --rosa: #{$rosa};
  
        /* luminarias */
        --encendida: #FFAB11;
        --apagada: #424242;
        --apagada-err: #E90000;
        --encendida-err: #B17D1C;
        --indeterminado: #3E51B5;
}
