@use "@angular/material" as mat;
@include mat.core();
@import "@ng-select/ng-select/themes/material.theme.css";
@import "./assets/estilos/light-theme.scss";
@import "./assets/fonts/fonts.css";
@import "./app/auxiliares/mat-table-responsive/mat-table-responsive.scss";
@import "./assets/estilos/ng-select.scss";
@import "./assets/estilos/style.scss";

.dark-theme {
  @import "./assets/estilos/dark-theme.scss";
  @include mat.all-component-themes($dark-theme);
  background-image: url("/assets/images/fondos/fondoOscuro1.jpg");
  color: white;

  // Colores
  --primary-color: #{$dark-primary-color};
  --primary-color-900: #{$dark-primary-color-900};
  --accent-color: #{$dark-accent-color};
  --warn-color: #{$dark-warn-color};
  --primary-color-contrast: #{$dark-primary-color-contrast};
  --accent-color-contrast: #{$dark-accent-color-contrast};
  --warn-color-contrast: #{$dark-warn-color-contrast};
  --bg-color-solid: #{$dark-bg-color-solid};
  --bg-color: #{$dark-bg-color};
  --fg-color: #{$dark-fg-color};
  --negro-transparente: #{$negro-transparente};
  --blanco-transparente: #{$blanco-transparente};
  --input-color: #{$dark-input-color};

      /* luminarias */
      --encendida: #FFAB11;
      --apagada: #424242;
      --apagada-err: #E90000;
      --encendida-err: #B17D1C;
      --indeterminado: #3E51B5;

  .bg-warn {
    background-color: var(--input-color);
    color: white;
  }
}

.highcharts-credits {
  display: none;
}
// .highcharts-background {
//   background-color: transparent !important;
// }

google-map > div > div > div > div:last-child {
  display: none;
}

.mdc-list-item__primary-text {
  color: var(--fg-color) !important;
}
.mat-mdc-slide-toggle .mdc-switch:enabled + .mdc-label {
  color: var(--fg-color) !important;
}

.mat-mdc-form-field:not(.mat-form-field-disabled)
  .mat-mdc-floating-label.mdc-floating-label {
  color: var(--fg-color) !important;
}

.mat-mdc-button {
  color: var(--fg-color) !important;
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "monserrat-regular", "arial", sans-serif;
  background-image: url("/assets/images/fondos/fondoClaro1.jpg");
  background-size: cover;
  background-position: center center;
}

mat-form-field {
  width: 100%;
}

.dialog-no-padding mat-dialog-container {
  padding: 0;
}

.nowrap {
  white-space: nowrap;
}

.link {
  cursor: pointer;
  color: #8f88e7;
  font-weight: 500;
}

.link-tabla {
  cursor: pointer;
  color: #8f88e7;
  font-weight: 500;
  margin: 0;
}

.encendido {
  background: green;
  color: white;
  padding: 0 5px;
  border-radius: 5px;
}

.apagado {
  background: #f44336;
  color: white;
  padding: 0 5px;
  border-radius: 5px;
}

.label-danger {
  background: #f44336;
  color: white;
  padding: 0 5px;
  border-radius: 5px;
}
.label-success {
  background: green;
  color: white;
  padding: 0 5px;
  border-radius: 5px;
}
.label-warnining,
.label-warn {
  background: #ffeb3b;
  color: black;
  padding: 0 5px;
  border-radius: 5px;
}
// Ocultar flechar de input number
/* Chrome, Safari, Edge, Opera */
* {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}

// Maps
* {
  .map-center > div {
    margin: auto;
  }

  .dark-theme {
    .gm-style .gm-style-iw-d::-webkit-scrollbar-track,
    .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
    .gm-style .gm-style-iw-c,
    .gm-style .gm-style-iw-t::after {
      // background: #252525 !important;
      background: transparent !important;
      box-shadow: none !important;
    }
  }
}
// Maps

// Tablas
* {
  mat-cell {
    padding: 3px;
    span {
      overflow: hidden;
    }
  }
  mat-cell:first-of-type,
  mat-header-cell:first-of-type,
  mat-footer-cell:first-of-type {
    padding-left: 10px !important;
  }
  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    padding: 0 10px !important;
  }

  .no-info {
    font-style: italic;
    color: gray;
    overflow: visible;
  }
}
.shadow {
  box-shadow: 0 5px 15px 3px #00000078 !important;
  border-top: 10px solid var(--accent-color);
  overflow: visible !important;
}

.mat-menu-panel {
  max-width: 600px !important;
}

.mat-mdc-menu-panel {
  max-width: 600px !important;
}

// SnackBar
* {
  .red-snackbar {
    background-color: #f44336;
    color: white;
  }
  .green-snackbar {
    background-color: #4caf50;
    color: white;
  }
  .yellow-snackbar {
    background-color: #ffeb3b;
    color: black;
  }
}

// Landing

.btn {
  width: 500px;
  max-width: 90%;
  margin: auto;
  height: 50px;
  margin-bottom: 1em;
}

// Navigator

.divider-navigator {
  --mat-divider-width: 1px;
  border-top-color: gray;
}

// Login

.titulo-login {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 1em;
}

.bg-transparente {
  background-color: transparent !important;
}

// .redondo-abajo {
//   max-height: 450px !important;
//   overflow-y: auto !important;
// }

.modal {
  max-height: 80vh !important;
  overflow-y: auto !important;
}

.redondo-abajo .mat-mdc-dialog-surface {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.redondo-abajo-izquierda .mat-mdc-dialog-surface {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 0 !important;
}

.container-fondo-semi {
  background-color: var(--bg-color-solid) !important;
  margin: 1em 0 !important;
  padding: 0.5em !important;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  overflow: hidden;
}

.fondo-semi {
  background-color: var(--bg-color-solid) !important;
  margin: 1em 0 !important;
  padding: 0.5em !important;
}

.fondo-semi-solo {
  background-color: var(--bg-color-solid) !important;
}

mat-form-field.no-hint .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.card-config {
  background-color: var(--bg-color-solid) !important;
  margin: 1em 0 !important;
  padding: 1em !important;
  border-radius: 15px;
}

// Cisternas CSS
.sin-alertas {
  font-weight: 500;
  color: #2faf2f;
}

.con-fallos {
  font-weight: 500;
  color: #f44336;
}

// Pozos CSS

.verde {
  font-weight: 500;
  color: #2faf2f;
}

.rojo {
  font-weight: 500;
  color: #f44336;
}

.amarillo {
  font-weight: 500;
  color: #ffeb3b;
}

.divider {
  --mat-divider-width: 3px;
}

.fuente {
  font-family: "monserrat-regular", "arial", sans-serif;
}

// Info Window

.info-window {
  width: 400px;
  //height: 220px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--bg-color-solid);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  //overflow: hidden !important;
  font-family: "monserrat-regular", "arial", sans-serif;
  p: {
    margin: 0;
    padding: 0;
    color: var(--fg-color);
  }

  .info-titulo {
    font-size: 1rem;
    font-weight: bold;
    width: 100%;
    background: var(--input-color);
    text-align: start;
    p {
      color: var(--fg-color);
      padding: 0.5em 0 0 0.7em;
      margin-bottom: 0;
    }
  }

  .info-subtitulo {
    font-size: 0.8rem;
    font-weight: bold;
    width: 100%;
    background: var(--input-color);
    text-align: start;
    margin-bottom: 0;
    p {
      color: var(--fg-color);
      padding: 0.2em 0.7em;
      margin: 0;
    }
  }

  .info-contenido {
    padding-top: 1em;
  }

  .item-list-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 1em 1em 0 1em;
    gap: 8px;
    .ng-star-inserted {
      width: 100%;
    }
  }

  .item-list {
    display: flex;
    width: calc(100% - 2em);
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: 5px;

    .nombre-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;

      p {
        font-size: 0.8rem;
        margin: 0;
        line-height: 1;
        width: 100%;
      }
    }
  }

  .alertas-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: auto;
    margin: 0;
    width: 100%;
    gap: 8px;

    p {
      font-size: 0.7rem;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .mini-text {
    font-size: 0.5rem;
    font-weight: 500;
  }

  .info-button {
    color: var(--fg-color);
    background-color: var(--input-color) !important;
    font-weight: 600;
    margin: 0.7em;
    border-radius: 8px;
    font-size: 13px;
  }

  .gm-style .gm-style-iw-c {
    padding: 0;
    margin: 0.5em;
    overflow: hidden !important;
    font: inherit;
    border-radius: 20px;
  }
}

:root .gm-style-iw-c,
:root .gm-style-iw-d {
  padding: 0;
  margin: 0;
  overflow: hidden !important;
  overflow-y: auto !important;
  font: inherit;
  border-radius: 20px;
}

mat-form-field > div:first-child {
  overflow: visible !important;
  z-index: 10;
}

// Un contenedor rancio de los infowindow
.gm-style .gm-style-iw-c {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background: transparent;
}

// En esto esta la cruz de cerrar el infoWindow
.gm-style-iw-chr {
  translate: 0 47px;
}

@media (max-width: 750px) {
  .fondo-semi {
    padding: 0 !important;
  }
}
